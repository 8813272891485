import React, { useState, useEffect } from "react"

import { useStaticQuery, graphql } from "gatsby"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import BigHeaderVideo from "../../../components/header/bigHeader/bigHeaderVideo"
import "../../../components/layout/layout.css"
import "../../../components/devicon/devicon.css"
import logo from "../../../../static/logo-bje.svg"
import Kontakt from "../../../components/kontakt"
import Navigation from '../../../components/projekte/navigation'
import {AnchorLink} from "gatsby-plugin-anchor-links"
import ThumbleisteSwiper from "../../../components/thumbleisteSwiper"
import Liste from "../../../components/liste"
import ListenModule from "../../../components/listenModule"
import myLocalVid from '../../../images/projekte/bje-baustellenplaner/busch-baustellenplaner-teaser.mp4'
import YouTube from "react-youtube"
import * as projekteStyles from "../projekte.module.css"
import Specs from "../../../components/specs"
import YtVideo from "../../../components/ytvideo"


const BuschJaegerBaustellenplaner = function (props, ref) {

    const opts = {
        width: '1180',
        height: '664',
        playerVars: {
          // https://developers.google.com/youtube/player_parameters
          autoplay: 1,
          rel:0
        }
    }

    const [enableYoutube, setEnableYoutube] = useState(true)

    useEffect(() => {
        setEnableYoutube(localStorage.getItem('enable-youtube'))
    }, [])


    const data = useStaticQuery(graphql`
        query BuschJaegerBaustellenplanerQuery {
            
            pic1: file(relativePath: { eq: "images/agenturportrait01.jpg" }) {
                childImageSharp {
                  fluid(
                    maxWidth: 1000,
                  ) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }

            heropic: file(relativePath: { eq: "images/projekte/bje-baustellenplaner/bje-baustellenplaner-hero.jpg" }) {
                childImageSharp {
                    gatsbyImageData(          
                        placeholder: BLURRED,
                        layout: FULL_WIDTH,
                        aspectRatio: 1.7
                    )
                  }
            }

            ogimage: file(relativePath: { eq: "images/projekte/bje-baustellenplaner/bje-baustellenplaner-hero.jpg" }) {
                childImageSharp {
                    fixed(height: 400) {
                        src
                        height
                        width
                    }
                }
            }

            allFile(filter: {extension: {regex: "/(jpg|png)/"}, relativeDirectory: {eq: "images/projekte/bje-baustellenplaner/thumbleiste"}}) {
                edges {
                  node {
                    id
                    childImageSharp {
                        picscropped:gatsbyImageData(placeholder: DOMINANT_COLOR, aspectRatio: 1, layout: FULL_WIDTH, formats: [WEBP], transformOptions: {rotate: 0, cropFocus: CENTER})
                        pics:gatsbyImageData(placeholder: DOMINANT_COLOR, layout: FULL_WIDTH, formats: [WEBP])
                      }
                  }
                }
              }                                     
        }
    `)

    const specsData = [
        { title: "Eckdaten", objects: [logo, "Web App", "https://baustellenplaner.busch-jaeger.de"] },
        { title: "Leistungen", objects: ["konzept", "design", "programmierung"] },
        {
          title: "Toolchain",
          objects: ["sketch", "html", "css", "js", "react"],
        },
      ]
  
    return (
        <Layout>
            <SEO
                title="Busch-Baustellenplaner"
                description="Q:marketing realisiert Konfigurator als Web App für Busch-Jaeger."
                image={data?.ogimage?.childImageSharp?.fixed}
            />

            <BigHeaderVideo mode="textFirst" className={`${projekteStyles.child}`} bgVideo={myLocalVid} videoMode="local">
                <div className="row py-5 py-md-0">
                    <div className="col-12 col-md-5">
                        <img src={logo} className="h-40px" />
                        <h1>Busch-Jaeger Baustellenplaner</h1>                                                
                        <p className="introtext">Klassische Elektroinstallation einfach geplant. Das smarte Tool sichert den einwandfreien Informationsfluss zwischen Baustelle, Büro und Großhandel.</p>
                        <AnchorLink to="/projekte/konfiguratoren/busch-jaeger-baustellenplaner/#thumbleiste" title="↓ Mehr Erfahren" className="q-btn q-btn-primary" />
                    </div>
                    <div className="col-7"></div>
                </div>
            </BigHeaderVideo> 

            {/* Bilderstrecke Thumbs */}   
            <ThumbleisteSwiper picdata={data.allFile} bgCol="#EFEFE6"/>                                         


            {/* Reasons why */}
            <ListenModule headline="Reasons why" margin={false}>
                <Liste
                    items={[
                        "Schalter- und Steckdosenkombinationen einfach planen.",
                        "Der Busch-Baustellenplaner unterstützt bei der Erstellung der Materialliste für die nächsten Arbeiten und sichert einen guten Informationsfluss zwischen Baustelle, Büro und Großhandel.",
                        "Schnelle und übersichtliche Materialaufnahme.",
                        "Vordefinierte Rennerkombinationen und Erstellen eigener individueller Kombinationen.",
                        "Bestellliste als PDF oder Excel oder zur weiteren Bearbeitung im Online-Katalog.",
                        "Bestellanbindung per ELBRIDGE an den EGH-Shop."
                    ]}
                />               
            </ListenModule>


            {/* Video */}
            {/* {enableYoutube &&
                <ListenModule headline="Video">
                    <div className={`col`}>
                        <div className="">                            
                            <YouTube
                                videoId="GaW4qwNoEaU"
                                opts={opts}
                            />
                        </div>
                    </div>
                </ListenModule>
            }      */}

            
            <ListenModule headline="Teaser">
                <div className="row">
                    <YtVideo videoId="GaW4qwNoEaU"/>                
                </div>
            </ListenModule>                 
           
             
            <Specs data={specsData} />

            {/* navigation */}            
            <Navigation location={props.location} />

            {/* kontakt */}            
            <Kontakt ansprechpartner="juergen-dworak"/>

        </Layout>
    )
}

export default BuschJaegerBaustellenplaner